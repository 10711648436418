@use "@wojo/ui/mixins" as *;
.nav {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
    margin-bottom: var(--g-spacing-xl);
    @include breakpoint-md {
        margin-bottom: var(--g-spacing-xxl);
    }
}

.bar {
    display: flex;
    margin-top: 1.5lh;
    padding: 0;
    list-style: none;
}
