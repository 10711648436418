@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .container {
        display: none;

        @include breakpoint-md {
            display: block;
        }
    }

    .track-wrapper {
        padding: 0 var(--g-spacing-lg);
        margin: 0 calc(var(--g-spacing-lg) * -1);

        & > div {
            align-items: center;
            height: 654px;
        }

        .slide {
            height: 589px;

            &--active {
                height: 654px;
                transition: height var(--g-duration-xshort) ease-in-out;
            }
        }
    }
}
