@use "../../../styles/mixins" as *;
@include layer(ui) {
    .trigger {
        all: unset;
        cursor: pointer;
        box-sizing: border-box;
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr min-content;
        color: var(--s-color-text-primary);
        padding: calc(var(--g-spacing-lg) - var(--g-border-width-sm));
        border: var(--g-border-width-sm) solid transparent;

        &-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-content-left {
            display: flex;
            align-items: flex-start;
            gap: var(--g-spacing-sm);
            margin-right: var(--g-spacing-sm);
        }

        &-title {
            font-weight: var(--g-font-weight-regular);
            font-size: var(--g-font-size-400);
            line-height: var(--g-line-height-400);
        }

        &--alert {
            background-color: var(--s-color-alert-background);
            color: var(--s-color-alert-standard);
            .trigger-title {
                color: var(--c-alert-collapsible-alert-text-color);
            }
            svg {
                color: currentColor;
            }
            &:focus-visible {
                .trigger-content {
                    outline-color: currentColor;
                }
            }
        }

        &--info {
            background-color: var(--s-color-info-background);
            color: var(--s-color-info-standard);
            .trigger-title {
                color: var(--c-alert-collapsible-info-text-color);
            }
            svg {
                color: currentColor;
            }
            &:focus-visible {
                .trigger-content {
                    outline-color: currentColor;
                }
            }
        }

        &--success {
            background-color: var(--s-color-success-background);
            color: var(--s-color-success-standard);
            .trigger-title {
                color: var(--c-alert-collapsible-success-text-color);
            }
            svg {
                color: currentColor;
            }
            &:focus-visible {
                .trigger-content {
                    outline-color: currentColor;
                }
            }
        }

        &:focus-visible {
            .trigger-content {
                outline-width: var(--g-border-width-sm);
                outline-style: solid;
                border-radius: var(
                    --c-alert-collapsible-trigger-border-radius-focus
                );
            }
        }
    }

    .collapsible {
        box-sizing: border-box;
        font-family: var(--g-font-family-primary);
        color: var(--s-color-text-primary);
        border-radius: var(--s-border-radius-background-standard);
        overflow: hidden;

        &--alert {
            border: var(--s-border-width-background-standard) solid
                var(--s-color-alert-border);
        }

        &--info {
            border: var(--s-border-width-background-standard) solid
                var(--s-color-info-border);
        }

        &--success {
            border: var(--s-border-width-background-standard) solid
                var(--s-color-success-border);
        }

        svg {
            transition: transform var(--g-duration-xshort) ease-in-out;
        }

        @media (prefers-reduced-motion) {
            svg {
                transition: none;
            }
        }
    }

    .dropdown-icon {
        height: var(--g-size-xxs);
        width: var(--g-size-xxs);
        stroke-width: var(--c-alert-collapsible-icon-stroke-width);
        flex-shrink: 0;
    }

    .expanded {
        .dropdown-icon {
            transform: rotate(180deg);
        }
    }

    .hide-border {
        border: var(--s-border-width-background-standard) solid transparent;
    }

    .content-inner {
        font-weight: var(--g-font-weight-light);
        padding: var(--c-alert-collapsible-content-inner-padding);
        display: flex;
        flex-direction: column;
        gap: var(--g-spacing-md);
        background-color: var(--s-color-surface-secondary);

        @include for-tablet-up {
            padding: var(--g-spacing-lg);
        }
    }
}
