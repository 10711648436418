.airline {
    margin-bottom: var(--g-spacing-xl);

    &:last-child {
        margin: 0;
    }

    .airline-detail:last-child {
        border: 0;
        margin: 0;
        padding: 0;
    }
}

.airline-title {
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);
    margin-bottom: var(--g-spacing-md);
    margin-top: var(--g-spacing-lg);
    padding-bottom: var(--g-spacing-md);
}

.airline-detail {
    align-items: flex-start;
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--g-spacing-md);
    padding-bottom: var(--g-spacing-md);
}

.flights-request {
    border-top: var(--g-border-width-xs) solid var(--s-color-separator-standard);
    margin-top: var(--g-spacing-md);
    padding-top: var(--g-spacing-md);
}
