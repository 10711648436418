@use "../../../styles/mixins" as *;
@include layer(ui) {
    $c-calendar-icon-width: var(--g-size-xxs);
    $c-calendar-input-spacing: var(--g-spacing-md);

    .wrapper {
        position: relative;
        label {
            display: flex;
            align-items: baseline;
        }
    }

    .calendar {
        outline: 1px solid transparent;
        border-radius: var(--s-border-radius-field-standard);
        background-color: var(--s-color-selection-background-default);
        width: 100%;
        margin-top: calc(
            var(--s-border-width-field-focus) +
                var(--s-border-width-field-default)
        );
        margin-left: -1px;
        box-shadow: (var(--s-shadow-standard-default));
        max-width: 380px;
        z-index: 10;
    }

    .display-label {
        display: none;
        max-width: calc(
            100% - #{$c-calendar-input-spacing}* 2 - #{$c-calendar-icon-width}
        );
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: var(--s-color-text-primary);
        &--unselected {
            color: var(--s-color-text-quiet);
        }
        &--disabled {
            color: var(--s-color-disabled-primary);
        }
    }

    .input-wrapper {
        position: relative;
        .input {
            &:not(:focus),
            &[aria-disabled]:focus {
                font-size: 0;
                & ~ .display-label {
                    position: absolute;
                    display: block;
                    left: var(--g-spacing-md);
                    top: 50%;
                    pointer-events: none;
                    transform: translateY(-50%);
                    font-family: var(--g-font-family-primary);
                    font-weight: var(--g-font-weight-regular);
                    font-size: var(--g-font-size-400);
                    line-height: var(--g-line-height-400);
                }
            }
        }
    }

    .input {
        min-height: inherit;
        padding-left: var(--g-spacing-md);
    }

    .icon {
        width: var(--g-size-xxs);
        color: currentColor;
    }

    .footer {
        border-top: var(--g-border-width-xs) solid
            var(--s-color-separator-standard);
        padding: var(--g-spacing-md);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
}
