@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .bar {
        display: none;

        @include breakpoint-lg {
            display: block;
        }
    }

    .open-button-wrapper {
        @include breakpoint-lg {
            display: none;
        }
    }

    .open-button {
        align-items: center;
        background: var(--g-color-white);
        border-radius: var(--c-filter-bar-border-radius);
        border: 0;
        box-shadow: var(--s-shadow-standard-default);
        color: var(--s-color-text-primary);
        cursor: pointer;
        display: flex;
        height: 48px;
        outline-offset: calc(-1 * var(--g-border-width-xs));
        outline: var(--g-border-width-xs) solid transparent;
        text-align: center;
        justify-content: center;
        width: 100%;

        &:focus-visible {
            outline: none;

            .button-text {
                border-radius: var(--c-filter-button-border-radius-focus);
                color: var(--s-color-brand-primary-standard);
                outline: var(--g-border-width-sm) solid
                    var(--s-color-brand-primary-standard);
            }
        }
    }

    .open-button-text {
        align-items: center;
        display: inline-flex;
        justify-content: center;
        gap: var(--g-spacing-sm);
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-400);
        font-weight: var(--g-font-weight-regular);
        line-height: var(--g-line-height-400);
        padding: var(--g-spacing-xs) var(--g-spacing-sm);
    }

    .open-button-icon {
        transform: rotate(90deg);
    }

    .modal-show-button-wrapper {
        height: 100px;
    }

    .modal-show-button-content {
        background: var(--s-color-surface-secondary);
        bottom: 0;
        box-shadow: var(--s-shadow-up);
        box-sizing: border-box;
        left: 0;
        padding: var(--g-spacing-md) var(--g-spacing-lg);
        position: fixed;
        text-align: center;
        width: 100%;
    }
}
