@use "@wojo/ui/mixins" as *;

.border {
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);
}

.container {
    padding: var(--g-spacing-lg) 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include breakpoint-lg {
        padding-top: calc(
            var(--g-spacing-md) + var(--g-spacing-sm) +
                var(--c-icon-button-secondary-small-height)
        );
    }
}

.logo-link {
    &:focus-visible {
        color: var(--s-color-brand-primary-standard);
        outline-color: currentColor;
        outline-width: var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xxs);
        outline-style: solid;
        border-radius: var(--c-header-link-outline-border-radius-focus);
    }
}

.logo {
    display: flex;
    img {
        height: 20px;
        width: auto;
        @include breakpoint-lg {
            height: 34px;
        }
    }
}

.phone-link {
    text-align: right;
    font-family: var(--g-font-family-primary);
    font-size: var(--c-header-main-nav-link-font-size);
    letter-spacing: var(--c-header-main-nav-link-letter-spacing);
    font-weight: var(--c-header-main-nav-link-font-weight);
    line-height: 120%;
    display: block;
}

.phone-number {
    display: flex;
    gap: var(--g-spacing-sm);
}

.phone-icon {
    height: 16px;
    width: 16px;
    padding-top: var(--c-header-main-nav-icon-spacing-top);
    text-decoration: none;

    @include breakpoint-lg {
        display: none;
    }
}
