.summary {
    margin-top: var(--g-spacing-sm);
    margin-bottom: var(--g-spacing-sm);
}

.travelers {
    display: grid;
    gap: var(--g-spacing-md);
    margin-bottom: var(--g-spacing-xl);
    margin-top: var(--g-spacing-xl);
}
