@use "@wojo/ui/mixins" as *;

.header {
    padding-bottom: var(--g-spacing-sm);
}

.terms-wrapper {
    border: var(--g-border-width-xs) solid var(--s-color-separator-standard);
    border-radius: var(--g-border-radius-lg);
    padding: var(--g-spacing-md);
    margin: var(--g-spacing-md) 0;
    height: 360px;
    overflow-y: scroll;
    scrollbar-width: none;
}

.acceptance-wrapper {
    margin-bottom: var(--g-spacing-md);
}

.checkbox-wrapper {
    margin-bottom: var(--g-spacing-md);
}

.divider {
    display: block;
    margin: 0 calc(-1 * var(--g-spacing-lg));
    border-top: 1px solid var(--s-color-separator-standard);

    @include breakpoint-md {
        margin: 0 calc(-1 * var(--g-spacing-xl));
    }
}

.button-wrapper {
    margin-top: var(--g-spacing-md);
    display: flex;
    justify-content: flex-end;
}
