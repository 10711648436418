@use "@wojo/ui/mixins" as *;

.detail-section {
    padding-bottom: var(--g-spacing-xxl);

    &:last-child {
        padding-bottom: 0;
    }

    .detail-section-title {
        padding-bottom: var(--g-spacing-xs);
    }

    .detail-section-subtitle {
        padding-bottom: var(--g-spacing-md);
    }

    .sub-title-button {
        padding-left: var(--g-spacing-xs);
    }
}

.detail-section-card {
    padding: var(--g-spacing-md);

    @include breakpoint-md {
        padding: var(--g-spacing-lg) var(--g-spacing-xl);
    }

    .passenger-detail {
        border-bottom: var(--g-border-width-xs) solid
            var(--s-color-separator-standard);

        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--g-spacing-md) 0;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        &--no-action {
            gap: var(--g-spacing-xs);
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
        }
    }
}
