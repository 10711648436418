@use "@wojo/ui/mixins" as *;
.main {
    display: flex;
    flex-direction: column;
}

.contents {
    @include breakpoint-lg {
        max-width: 480px;
    }
}

.container {
    @include breakpoint-lg {
        margin-top: var(--g-spacing-xxl);
        display: grid;
        grid-template-columns: max-content 368px;
        gap: var(--g-spacing-xxl);
        justify-content: center;
    }
}
