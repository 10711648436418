@mixin future-steps {
    .step--current &,
    .step--current ~ .step & {
        @content;
    }
}

.step {
    counter-increment: step;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    &:first-child {
        flex-grow: 0;
    }

    --node-border-color: var(--s-color-brand-primary-standard);
    --node-background-color: var(--s-color-brand-primary-standard);
    --node-text-color: var(--s-color-text-inverse);
    &--current {
        & ~ .step {
            --node-border-color: currentColor;
            --node-background-color: var(--s-color-surface-secondary);
            --node-text-color: var(--s-color-text-primary);
            &--disabled {
                --node-border-color: var(--s-color-disabled-primary);
                --node-background-color: var(--s-color-surface-secondary);
                --node-text-color: var(--s-color-disabled-primary);
            }
        }
        @media (forced-colors: active) {
            --node-background-color: Highlight;
        }
    }
}

.connector {
    flex-grow: 1;
    border-top: var(--g-border-width-xs) solid var(--node-border-color);
    .step:first-child & {
        display: none;
    }
}

.node {
    display: inline;
    font-size: min(var(--g-font-size-300), 26px);
    line-height: var(--g-line-height-300);
    .step--current &,
    .step--disabled & {
        text-decoration: none;
    }
    &:focus-visible:not(:disabled),
    &:visited:focus-visible:not(:disabled) {
        outline: none;
        .label {
            border-radius: inherit;
            outline-offset: inherit;
            border-color: var(--border-color-focus);
            color: var(--text-color-focus);
            outline-color: var(--outline-color-focus);
            outline-style: solid;
        }
    }
}

.label {
    position: absolute;
    bottom: 100%;
    margin-bottom: var(--g-spacing-xs);
    left: 50%;
    transform: translateX(-50%);
    text-decoration: inherit;
    .step:last-child & {
        left: unset;
        right: 0;
        transform: translateX(0);
    }
    .step:first-child & {
        left: 0;
        transform: translateX(0);
    }
}

.step-number {
    border: var(--g-border-width-xs) solid var(--node-border-color);
    background-color: var(--node-background-color);
    color: var(--node-text-color);
    display: inline-flex;
    font-weight: var(--g-font-weight-bold);
    border-radius: 50%;
    min-width: var(--g-size-sm);
    min-height: var(--g-size-sm);
    width: var(--g-font-size-400);
    aspect-ratio: 1/1;
    align-items: center;
    justify-content: center;

    @include future-steps {
        &:before {
            content: counter(step);
            text-decoration: none;
        }
    }
}

.completed {
    stroke: currentColor;
    @include future-steps {
        display: none;
    }
}
