@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .modal {
        position: relative;
        padding: 0;
        overflow: hidden;

        &::before {
            position: absolute;
            content: "";
            display: flex;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: var(--overlay-opacity);
            z-index: 1;
            pointer-events: none;
        }

        &--light-text-over-dark-overlay {
            &::before {
                background: linear-gradient(
                    to bottom,
                    var(--g-color-ink-black),
                    transparent 33%
                );
            }
            .heading {
                color: var(--g-color-white);
            }
        }

        &--dark-text-over-light-overlay {
            &::before {
                background: linear-gradient(
                    to bottom,
                    var(--g-color-white),
                    transparent 33%
                );
            }
            .heading {
                color: var(--g-color-ink-black);
            }
        }
    }

    .heading {
        position: absolute;
        top: var(--g-spacing-md);
        left: var(--g-spacing-md);
        right: calc(var(--g-spacing-md) * 4);
        z-index: 1;
        display: flex;
    }

    .slider {
        display: flex;
        position: relative;
        height: 100%;
        width: 100%;

        .track {
            display: grid;
            grid-template-rows: repeat(var(--nb-items), 100%);
            padding: 0px;
            width: 100%;
            height: 100%;
        }

        .slide {
            display: flex;
            flex: 0 0 100%;
            height: 100%;
            width: 100%;
        }
    }

    .close {
        position: absolute;
        top: var(--g-spacing-md);
        right: var(--g-spacing-md);
        height: 34px;
        width: 34px;
        z-index: 1;
        color: var(--g-color-ink-black);
        padding: 0;

        @media (pointer: coarse) {
            &:focus-visible {
                outline: none;
            }
        }
    }

    .video-player {
        height: 100%;
        width: 100%;
        video {
            object-fit: cover !important;
        }
    }
}
