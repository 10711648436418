@use "@wojo/ui/mixins" as *;

.card {
    display: none;

    @include breakpoint-lg {
        display: grid;
        grid-template-columns: auto auto;
        font-family: var(--g-font-family-primary);
        letter-spacing: var(--g-letter-spacing-standard);
        font-size: var(--g-font-size-standard);
        margin-bottom: var(--g-spacing-xl);
    }
}

.button {
    justify-self: flex-end;
}

.total {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-start;
    align-items: center;
    gap: var(--g-spacing-sm);
}
