@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .collapsible-description {
        padding-bottom: var(--g-spacing-md);
    }

    .collapsible-action {
        display: flex;
        width: fit-content;
        &:not(:last-child) {
            padding-bottom: var(--g-spacing-md);
        }
    }
}
