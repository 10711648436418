.content {
    padding-bottom: var(--g-spacing-xl);
}

.title {
    margin-bottom: var(--g-spacing-sm);
}

.footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
