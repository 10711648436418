@use "@wojo/ui/mixins" as *;

.form-layout {
    display: grid;
    grid: auto;
    gap: var(--g-spacing-md);
    align-items: flex-start;
    @include breakpoint-sm {
        grid-template-columns: 1fr min-content;
    }
}
.submit-button {
    margin-top: 0;
    @include breakpoint-sm {
        margin-top: var(--g-spacing-lg); // offset from top label
    }

    justify-self: flex-start;
}
