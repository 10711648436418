@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .container {
        display: flex;

        &-gap--xsmall {
            gap: var(--g-spacing-xs);
        }

        &-gap--small {
            gap: var(--g-spacing-sm);
        }

        &-gap--medium {
            gap: var(--g-spacing-md);
        }

        &-gap--large {
            gap: var(--g-spacing-lg);
        }
    }

    .image-graphic {
        display: flex;
        flex-direction: column;

        img {
            object-fit: contain;
            width: calc(var(--image-graphic-width) * 0.8);
            height: auto;

            @include breakpoint-md {
                width: var(--image-graphic-width);
            }
        }

        &--top {
            justify-content: flex-start;
        }

        &--middle {
            justify-content: center;
        }

        &--bottom {
            justify-content: flex-end;
        }
    }

    .content {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: var(--g-spacing-sm);
    }
}
