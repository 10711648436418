@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        min-height: 100%;
        position: relative;

        @include breakpoint-md {
            aspect-ratio: 9 / 16;
        }

        &::before {
            position: absolute;
            content: "";
            display: flex;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: var(--overlay-opacity, 0.4);
            z-index: 1;

            @include breakpoint-md {
                pointer-events: none;
            }
        }
        &--light-text-over-dark-overlay {
            &::before {
                background: linear-gradient(
                    to bottom,
                    var(--g-color-ink-black),
                    transparent 50%
                );

                @include breakpoint-md {
                    background: linear-gradient(
                        to bottom,
                        var(--g-color-ink-black),
                        transparent 33%
                    );
                }
            }
            .heading {
                color: var(--g-color-white);
                pointer-events: none;
            }
        }
        &--dark-text-over-light-overlay {
            &::before {
                background: linear-gradient(
                    to bottom,
                    var(--g-color-white),
                    transparent 50%
                );

                @include breakpoint-md {
                    background: linear-gradient(
                        to bottom,
                        var(--g-color-white),
                        transparent 33%
                    );
                }
            }
            .heading {
                color: var(--g-color-ink-black);
            }
        }
    }

    .heading {
        position: absolute;
        top: var(--g-spacing-md);
        left: var(--g-spacing-md);
        right: calc(var(--g-spacing-md));
        z-index: 1;
        display: flex;
    }

    .wrapper--fullscreen {
        .video-player {
            video {
                object-fit: contain !important;
            }
        }
    }

    .video-player {
        box-shadow: var(--s-shadow-standard-default);

        & > div {
            & > div {
                // Override Wistia container height to handle height change
                height: 100% !important;
            }
        }

        &--playing {
            &:hover {
                box-shadow: var(--s-shadow-standard-active);
            }
        }
    }
}
