@use "@wojo/ui/mixins.scss" as *;

.grid {
    @include breakpoint-lg {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: var(--g-spacing-xl);
        & > * {
            grid-column: span 5;
            &:first-child {
                grid-column: span 7;
            }
        }
    }
}
