.content {
    padding: var(--g-spacing-md);
}

.title {
    margin-top: var(--g-spacing-lg);
    margin-bottom: var(--g-spacing-md);
}

.excursion-modal-image {
    display: flex;
    aspect-ratio: 16/9;
    margin-bottom: var(--g-spacing-lg);
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: center;
    }
}

.excursion-modal-detail {
    margin-bottom: var(--g-spacing-sm);
    display: flex;
    align-items: center;
    gap: var(--g-spacing-sm);
}

.divider {
    height: var(--g-border-width-xs);
    background-color: var(--s-color-separator-standard);
    margin: var(--g-spacing-md) 0;
}
