.customer-list {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-sm);
    list-style: none;
    margin: 0;
    padding: 0;
}

.customer-list-item {
    margin: 0;
    padding: 0;
}
