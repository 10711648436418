@use "@wojo/ui/mixins" as *;

.modal-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 var(--g-spacing-lg) var(--g-spacing-xxl);
    @include breakpoint-md {
        padding: 0 var(--g-spacing-xl) var(--g-spacing-xxl);
    }
}

.spacer {
    height: 24px;
    margin: var(--g-spacing-sm) 0;
}
