@use "@wojo/ui/mixins" as *;

.title {
    margin-top: var(--g-spacing-xl);
    margin-bottom: var(--g-spacing-sm);
}

.description {
    margin-bottom: var(--g-spacing-lg);
}

.message {
    margin-top: var(--g-spacing-lg);
}

.label-detail {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--g-spacing-sm);
}

.wrapper {
    margin-bottom: var(--g-spacing-xl);
}

.desktop-only {
    display: none;
    @include breakpoint-lg {
        display: inline;
    }
}

.mobile-only {
    @include breakpoint-lg {
        display: none;
    }
}
