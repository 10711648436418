@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .image-wrapper {
        display: inline-block;
        overflow: hidden;
        max-width: 100%;
        border-radius: var(--page-link-border-radius);

        & > *:focus-visible,
        & > *:focus {
            outline: none;
        }
    }

    .image {
        display: flex;
        aspect-ratio: var(--page-link-image-aspect-ratio, auto);
        position: relative;

        img {
            border-radius: var(--page-link-border-radius);
            transition: transform var(--g-duration-xshort);
            height: auto;
            width: 100%;

            @media (prefers-reduced-motion: reduce) {
                transition: none;
            }
        }

        & > *:focus-visible,
        & > *:focus {
            outline: none;
        }
    }
}
