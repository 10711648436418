@use "@wojo/ui/mixins" as *;

.card {
    padding: 0;
    // prevent card image from bleeding out of rounded corners
    overflow: hidden;

    &--full-page {
        max-width: 100%;
    }

    @include breakpoint-lg {
        max-width: 100%;
    }
}

.heading {
    padding: var(--g-spacing-sm) var(--g-spacing-md) 0;

    &--full-page {
        padding: var(--g-spacing-sm) var(--g-spacing-xl) 0;
    }
}

.image {
    display: block;
    height: 188px;
    background-color: var(--s-color-surface-primary);

    img {
        height: 188px;
        width: 100%;
        object-fit: cover;
    }
}

.date {
    display: grid;
    grid-template-columns: min-content max-content;
    align-items: center;
    gap: var(--g-spacing-sm);
}

.summary-list {
    display: grid;
    padding: var(--g-spacing-md);

    > * {
        padding-bottom: var(--g-spacing-md);
        padding-top: var(--g-spacing-md);
        border-bottom: 1px solid var(--s-color-neutral-border);

        &:last-child {
            border-bottom: none;
        }
    }

    &--full-page {
        padding: var(--g-spacing-md) var(--g-spacing-xl);
    }
}

.alert {
    margin: var(--g-spacing-xl) var(--g-spacing-xl) var(--g-spacing-md);

    &--expired {
        a {
            color: var(--c-alert-banner-alert-text-color);
        }
    }
}
.alert-title {
    color: var(--c-alert-banner-info-text-color);
}

.summary-item {
    padding: var(--g-spacing-md) 0 var(--g-spacing-md) 0;

    button[aria-expanded="true"] {
        .icon {
            transform: rotate(180deg);
        }
    }

    button[aria-expanded="false"] {
        .icon {
            transform: rotate(0);
        }
    }

    &--collapsible-title {
        display: grid;
        grid-template-columns: auto 1fr auto;

        .icon {
            transition: transform var(--g-duration-xshort) ease-in-out;
            justify-self: left;
            margin-left: var(--g-spacing-sm);
        }
    }

    &--collapsible {
        .summary-item-content {
            margin-top: var(--g-spacing-sm);
        }

        .summary-item-content:first-child {
            margin-top: 0;
        }
    }
}

.summary-item-content {
    display: flex;
    justify-content: space-between;
    gap: var(--g-spacing-xl);
}

.discount-item {
    color: var(--s-color-promo-price-standard);
}

.totals {
    background-color: var(--s-color-brand-primary-quiet);
    padding: var(--g-spacing-md);
    display: grid;
    gap: var(--g-spacing-sm);

    &--full-page {
        padding: var(--g-spacing-md) var(--g-spacing-xl);
    }
}

.deposit {
    background-color: var(--s-color-neutral-background);
    padding: var(--g-spacing-sm) var(--g-spacing-md);
    &--full-page {
        padding: var(--g-spacing-sm) var(--g-spacing-xl);
    }
}

.payment {
    padding: var(--g-spacing-md);
    &--full-page {
        padding: var(--g-spacing-md) var(--g-spacing-xl);
    }
}

.payment-full--full-page {
    display: flex;
    justify-content: center;
}

.modal-body {
    display: grid;
    gap: var(--g-spacing-md);
}

.rating {
    &--full-page {
        margin: 0 var(--g-spacing-md);
    }
}
