.toggles {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
    padding-top: var(--g-spacing-md);
}

.btn-group {
    display: flex;
    align-items: center;
    gap: var(--g-spacing-sm);
    margin-top: var(--g-spacing-md);
}

.line-item-wrapper {
    padding-top: var(--g-spacing-md);
    padding-bottom: var(--g-spacing-md);
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);
}

.line-item {
    display: flex;
    align-items: center;
    gap: var(--g-spacing-sm);
}

.line-item-text {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--g-spacing-sm);
    color: var(--s-color-success-standard);
    margin: 0;
}
