.hotels-table {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-lg);
    margin-bottom: var(--g-spacing-xxl);
}
.hotels-table > :not(:last-child) {
    padding-bottom: var(--g-spacing-lg);
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);
}

.hotel-table-row {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-sm);
    dl,
    dt {
        margin: 0;
    }
}

.hotel-table-item {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: flex-start;

    .hotel-table-name-dates {
        display: flex;
        flex-direction: column;
        gap: var(--g-spacing-xxs);
    }
    dd {
        margin: 0 0 0 var(--g-spacing-md);
    }

    &--info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: end;
    }

    &--address {
        font-style: normal;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}
