@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .card {
        --aspect-ratio: auto;
        --gradient-angle: 0deg;
        --overlay-color: 0, 0, 0;
        --overlay-opacity: 0.4;

        aspect-ratio: var(--aspect-ratio);
        display: flex;
        border-radius: 0;
        padding: 0;
        border-width: 0;

        &--border-radius {
            border-radius: var(--s-border-radius-background-standard);
            overflow: hidden;
        }

        &--flat {
            overflow: initial;
        }

        @media (forced-colors: active) {
            border-width: 2px;
        }
    }

    .border-container {
        border-radius: inherit;
        display: flex;
        aspect-ratio: inherit;
        box-sizing: border-box;
        min-height: 100%;
        min-width: 100%;
        overflow: hidden;
    }

    .card--flat {
        .border-container {
            overflow: initial;
        }
    }

    .content-container {
        padding: var(--g-spacing-lg);
        display: flex;
        flex-direction: column;
        min-height: 100%;
        min-width: 100%;
        box-sizing: border-box;
        &,
        & > * {
            position: relative;
        }

        @include for-tablet-up {
            padding: var(--g-spacing-xl);
        }

        &--background-color {
            background: var(--background-color);
        }

        &--background-gradient {
            background: linear-gradient(
                var(--background-color-gradient-angle),
                var(--background-start-color),
                var(--background-end-color)
            );
        }

        &--flat {
            padding: 0;
        }

        &--x-left {
            align-items: flex-start;
        }
        &--x-center {
            align-items: center;
            text-align: center;
        }
        &--y-top {
            justify-content: flex-start;
        }
        &--y-middle {
            justify-content: center;
        }
        &--y-bottom {
            justify-content: flex-end;
        }
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform var(--g-duration-xshort) ease;
        .card--button:hover &,
        .card--button:focus-visible & {
            transform: scale(1.025);
        }

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }

    .card-item {
        &:not(:first-child) {
            padding-top: var(--g-spacing-md);
        }

        .actions {
            &:not(:first-child) {
                padding-top: var(--g-spacing-md);
            }
        }
    }
}
