@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .container {
        overflow: hidden;
    }

    .grid {
        display: flex;
        flex-wrap: nowrap;
        margin-top: var(--g-spacing-lg);
        margin-bottom: var(--g-spacing-xl);

        @include breakpoint-md {
            overflow: hidden;
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        flex: none;
        width: 280px;

        .card-content {
            display: flex;
            flex-direction: column;
            gap: var(--g-spacing-sm);
            margin-top: var(--g-spacing-md);
        }
    }

    .image {
        height: 194px;
        aspect-ratio: 3/2;
        width: 100%;
    }
}
