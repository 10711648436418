.wrapper {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    align-content: center;
}
.horizontal-divider {
    height: 1px;
    background-color: var(--s-color-separator-standard);
    margin: var(--g-spacing-md) 0;
}
