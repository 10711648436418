@use "@wojo/ui/mixins" as *;

.section {
    margin-top: var(--g-spacing-lg);
    margin-bottom: var(--g-spacing-xl);

    @include breakpoint-md {
        margin-bottom: var(--g-spacing-xxl);
    }
}

.overview-columns {
    display: grid;
    grid-gap: var(--g-spacing-xl);
    margin-top: var(--g-spacing-xl);
    @include breakpoint-md {
        grid-template-columns: 1fr 1fr;
    }
}
