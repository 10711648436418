@use "@wojo/ui/mixins" as *;

.card {
    border: none;
    font-family: var(--g-font-family-primary);
    padding: 0;
    position: relative;

    &--column {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: auto;
        min-height: 152px;

        @include breakpoint-md {
            min-height: 204px;
        }
    }

    &--row {
        @include breakpoint-sm {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 1fr 2fr;
            min-height: 204px;
        }
    }
}

.image {
    position: relative;

    &--column {
        img {
            border-top-left-radius: var(--s-border-radius-background-standard);
            border-bottom-left-radius: var(
                --s-border-radius-background-standard
            );
        }
    }

    &--row {
        aspect-ratio: 16/9;

        @include breakpoint-sm {
            aspect-ratio: auto;
        }

        img {
            border-top-left-radius: var(--s-border-radius-background-standard);
            border-top-right-radius: var(--s-border-radius-background-standard);

            @include breakpoint-sm {
                border-top-right-radius: 0;
                border-bottom-left-radius: var(
                    --s-border-radius-background-standard
                );
                left: 0;
                position: absolute;
                top: 0;
            }
        }
    }
}

.content {
    align-self: center;
    padding: var(--g-spacing-md);

    &--column {
        @include breakpoint-md {
            padding: var(--g-spacing-xl);
        }
    }

    &--row {
        @include breakpoint-sm {
            padding: var(--g-spacing-xl);
        }
    }
}

.title {
    margin-bottom: var(--g-spacing-xs);
}

.see-details-wrapper {
    margin-top: var(--g-spacing-sm);
}

.see-details {
    position: static;

    &::before {
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
    }
}

.status-label {
    display: flex;
    margin-bottom: var(--g-spacing-md);
}

.footer {
    position: relative;
    z-index: 1;
}
