@use "@wojo/ui/mixins" as *;

$day-marker-width: 7px;
$day-wrapper-spacing: var(--g-spacing-md);
$day-by-day-border-width: 1px;
$calendar-day-height: 64px;

.day-by-day {
    border-left: $day-by-day-border-width solid var(--s-color-text-primary);
    border-image: linear-gradient(
            to top,
            transparent,
            currentColor var(--g-spacing-md)
        )
        1;
    contain: layout;
    margin-top: var(--g-spacing-xl);
    padding-bottom: var(--g-spacing-md);
    padding-left: $day-wrapper-spacing;
}

.day {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-sm);
    position: relative;

    @include breakpoint-md {
        flex-direction: row;
        gap: var(--g-spacing-md);
    }

    & + & {
        margin-top: var(--g-spacing-xl);
    }
}

.calendar-date {
    background: var(--s-color-surface-quiet);
    border-radius: var(--s-border-radius-background-small);
    border: var(--g-border-width-xs) solid transparent;
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-xxs);
    min-width: var(--g-size-xxl);
    padding: var(--g-spacing-xs) 0;
    text-align: center;
    width: max-content;
}

.calendar-day-of-month {
    font-family: var(--g-font-family-primary);
    font-size: var(--g-font-size-600);
    font-weight: var(--g-font-weight-bold);
    line-height: 1;
}

.day-title {
    margin-bottom: var(--g-spacing-sm);
}

.day-overnight-title {
    margin-bottom: var(--g-spacing-md);
}

.day-overnight-number {
    font-size: inherit;
    display: inline;
    padding-left: var(--g-spacing-md);
    margin-left: var(--g-spacing-md);
    border-left: 1px solid var(--s-color-separator-standard);
}

.day-marker-group {
    position: relative;

    & + & {
        margin-top: var(--g-spacing-xl);
    }
}

.day-marker {
    left: calc(#{$day-wrapper-spacing} * -1);
    position: relative;

    svg {
        fill: currentColor;
        height: $day-marker-width;
        position: absolute;
        transform: translate(-50%, -50%);
        width: $day-marker-width;
    }

    &--itinerary-item {
        font-size: var(--g-font-size-600);
        line-height: var(--g-line-height-600);

        svg {
            top: 0.5lh;
        }
    }

    &--tour-day {
        font-size: var(--g-font-size-500);
        line-height: var(--g-line-height-500);

        svg {
            top: calc($calendar-day-height / 2);

            @include breakpoint-md {
                top: 0.5lh;
            }
        }
    }

    &--first {
        // Hides the top of the border line that appears above the dot icon
        &::before {
            border-left: $day-by-day-border-width solid
                var(--s-color-surface-primary);
            content: "";
            left: -1px;
            position: absolute;
            top: 0;
            width: 0;

            @media (forced-colors: active) {
                border-color: Canvas;
            }
        }
    }

    &--first.day-marker--tour-day {
        &::before {
            height: calc($calendar-day-height / 2);

            @include breakpoint-md {
                height: 0.5lh;
            }
        }
    }

    &--first.day-marker--itinerary-item {
        &::before {
            height: 0.5lh;
        }
    }
}

.day-meals {
    align-items: flex-start;
    display: inline-flex;
    gap: var(--g-spacing-sm);
    margin-bottom: var(--g-spacing-sm);
}

.print-button {
    & > * {
        vertical-align: middle;
    }
}

.itinerary-map {
    display: flex;
    margin-bottom: var(--g-spacing-md);

    img {
        border-radius: var(--s-border-radius-background-standard);
        max-width: 100%;
    }
}

.free-time-title {
    align-items: center;
    display: flex;
    gap: var(--g-spacing-sm);
    margin-bottom: var(--g-spacing-md);
}

.highlights {
    background-color: var(--s-color-brand-primary-quiet);
    border: var(--g-border-width-xs) solid transparent;
    border-radius: var(--s-border-radius-background-standard);
    margin-top: var(--g-spacing-lg);
    padding: calc(var(--g-spacing-lg) - var(--g-border-width-xs));
}

.highlights-title {
    align-items: center;
    display: flex;
    gap: var(--g-spacing-sm);
    margin-bottom: var(--g-spacing-sm);
}

.physical-activity-title {
    align-items: center;
    display: flex;
    gap: var(--g-spacing-sm);
    margin-bottom: var(--g-spacing-sm);
}

.physical-activity-markdown {
    ul {
        padding-left: var(--g-spacing-md);
    }
}
