.affirm-banner {
    background-image: linear-gradient(
        95deg,
        var(--s-color-accent2-quiet) 0%,
        var(--s-color-accent3-quiet) 100%
    );
    padding: var(--g-spacing-lg);
    :global(.affirm-ala-price) {
        font-weight: var(--g-font-weight-bold);
    }
    :global(.affirm-modal-trigger) {
        display: none;
    }
}
