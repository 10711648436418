@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .page-link-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--g-spacing-lg);

        &-no-image {
            padding: var(--g-spacing-md) var(--g-spacing-lg);
            border-radius: var(--s-border-radius-background-standard);
            @include breakpoint-md {
                padding: var(--g-spacing-lg);
            }
        }

        &:focus-visible,
        &:focus-within {
            outline: none;
        }
    }

    .text {
        flex: 1 0 0;
        margin: 0;
        font-family: var(--g-font-family-primary);
        color: var(--s-color-text-primary);
        font-size: var(--g-font-size-400);
        line-height: var(--g-line-height-400);
        font-weight: var(--g-font-weight-regular);

        &--regular {
            font-weight: var(--g-font-weight-regular);
        }

        &--bold {
            font-weight: var(--g-font-weight-bold);
        }
    }

    .icon {
        height: 24px;
        width: 24px;
        margin-left: var(--g-spacing-md);
        transition: transform var(--g-duration-xshort) ease;
        color: var(--s-color-icon-primary);

        @media (forced-colors: active) {
            color: currentColor;
        }

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }

    .text-and-icon-color {
        color: var(--page-link-text-and-icon-color);

        @media (forced-colors: active) {
            color: currentColor;
        }
    }

    .custom-text-and-icon-color {
        color: var(--page-link-custom-text-and-icon-color);

        @media (forced-colors: active) {
            color: currentColor;
        }
    }
}
