.card {
    padding: 0;
    overflow: hidden;
}

.actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: var(--g-spacing-lg);

    a,
    button {
        margin-bottom: var(--g-spacing-lg);
    }
}
