@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .merch-card-wrapper {
        border-radius: var(--s-border-radius-background-standard);
        width: 280px;
        min-width: 280px;
        box-sizing: border-box;
        display: grid;
    }
}
