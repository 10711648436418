@use "../../../styles/mixins" as *;
@include layer(ui) {
    .wrapper {
        display: flex;
        padding: var(--c-alert-banner-padding);
        border-radius: var(--s-border-radius-background-standard);
        gap: var(--g-spacing-sm);
        border: var(--s-border-width-background-standard) solid;

        @include for-tablet-up {
            padding: var(--g-spacing-lg);
        }
    }

    .alert {
        &--alert {
            background-color: var(--s-color-alert-background);
            border-color: var(--s-color-alert-border);
            .icon {
                color: var(--c-alert-banner-alert-icon-color);
            }
            .text {
                color: var(--c-alert-banner-alert-text-color);
            }
        }

        &--success {
            background-color: var(--s-color-success-background);
            border-color: var(--s-color-success-border);
            .icon {
                color: var(--c-alert-banner-success-icon-color);
            }
            .text {
                color: var(--c-alert-banner-success-text-color);
            }
        }
        &--info {
            background-color: var(--s-color-info-background);
            border-color: var(--s-color-info-border);
            .icon {
                color: var(--c-alert-banner-info-icon-color);
            }
            .text {
                color: var(--c-alert-banner-info-text-color);
            }
        }
        &--no-border {
            border-color: transparent;
        }
    }

    .layout {
        &--inline {
            & > * {
                display: inline;
                &:not(:last-child):after {
                    content: " ";
                }
            }
        }
        &--stack {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .cta {
        margin-top: var(--g-spacing-xs);
    }
}
