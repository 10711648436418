@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .card {
        border-width: 0;
        aspect-ratio: var(--aspect-ratio, auto);
        padding: 0;
        display: flex;
        border-radius: 0;
        overflow: auto;

        &--border-radius {
            border-radius: var(--s-border-radius-background-standard);
        }

        &--no-bottom-border-radius {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        &--flat-link {
            text-decoration: none;
        }

        @media (forced-colors: active) {
            outline: 2px solid;
        }
    }

    .text-container {
        padding: var(--g-spacing-lg);

        &--flat-padding {
            padding: var(--g-spacing-lg) 0 0 0;
        }
    }

    .title-description {
        display: flex;
        flex-direction: column;

        & > div {
            &:not(:first-child) {
                padding-top: var(--g-spacing-sm);
            }
        }
    }

    .content-container {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        min-width: 100%;
        box-sizing: border-box;
        border-radius: inherit;
        &,
        & > * {
            position: relative;
        }
        &--background-color {
            background-color: var(--background-color);
        }

        &--x-left {
            align-items: flex-start;
        }
        &--x-center {
            align-items: center;
            text-align: center;
        }
        &--y-top {
            justify-content: flex-start;
        }
        &--y-middle {
            justify-content: center;
        }
        &--y-bottom {
            justify-content: flex-end;
        }
    }
    .image {
        display: flex;
        width: 100%;

        img {
            aspect-ratio: var(--aspect-ratio, auto);
            border-radius: var(--border-radius, 0);
            height: auto;
            width: 100%;
        }
    }

    .border {
        display: flex;
        width: 100%;

        &--circular {
            border-radius: 50%;
        }
    }

    .cta {
        margin-top: var(--g-spacing-md);
    }
}
