@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .anchor-nav {
        min-height: 60px;
        background-color: transparent;
        display: flex;
        align-items: flex-end;
        scroll-behavior: smooth;
        transition: scroll-behavior var(--g-duration-short) ease-out;

        @media (prefers-reduced-motion: reduce) {
            transition: none;
            scroll-behavior: auto;
        }

        &--flat {
            border-bottom: 1px solid var(--s-color-separator-standard);
        }

        &--left {
            justify-content: flex-start;
        }
        &--center {
            justify-content: safe center;

            //HACK: Safari does not support `justify-content: safe center`
            @supports (hanging-punctuation: first) and
                (-webkit-appearance: none) {
                justify-content: flex-start;
            }
        }

        ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding-left: 0;

            li {
                margin-right: var(--g-spacing-md);
                padding-bottom: 0;
                white-space: nowrap;

                @include breakpoint-md {
                    margin-right: var(--g-spacing-xl);
                }

                a {
                    font-family: var(--g-font-family-primary);
                    font-size: var(--g-font-size-400);
                    color: var(--s-color-tab-foreground-default);
                    text-decoration: none;
                    padding-bottom: var(--g-spacing-md);
                    display: block;

                    &:hover {
                        color: var(--s-color-tab-foreground-active);
                    }

                    &:focus-visible {
                        border-bottom: 3px solid var(--s-color-tab-border-focus);
                        color: var(--s-color-tab-foreground-active);
                        outline: none;
                    }
                }

                &.active-anchor {
                    a {
                        border-bottom: 3px solid
                            var(--s-color-tab-border-selected);
                        color: var(--s-color-tab-foreground-selected);

                        &:focus-visible {
                            border-bottom: 3px solid
                                var(--s-color-tab-border-focus);
                            color: var(--s-color-tab-foreground-active);
                            outline: none;
                        }
                    }
                }
            }
        }
    }

    .anchor-nav-container {
        overflow-x: auto;

        &--card {
            background-color: var(--s-color-surface-secondary);
            box-shadow: var(--s-shadow-down);
            clip-path: inset(0px -10px -10px -10px);
        }

        &--sticky {
            position: sticky;
            top: 0;
            background-color: var(--s-color-surface-secondary);
            z-index: 3;
        }
    }

    .anchor-section {
        display: flex;
        flex-direction: column;

        &--sticky {
            scroll-margin-top: var(--anchor-nav-height);
        }
    }
}
