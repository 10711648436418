@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .section-container {
        display: flex;
        flex-direction: column;
    }

    .wrapper-background-color {
        background: var(--section-background-color);
        overflow: clip;
    }

    .wrapper-background-gradient {
        background: linear-gradient(
            var(--section-background-gradient-angle),
            var(--section-background-start-color),
            var(--section-background-end-color)
        );
        overflow: clip;
    }

    .text-align {
        &--center {
            text-align: center;
        }
        &--left {
            text-align: left;
        }
    }
}
