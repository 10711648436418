.alert {
    margin-bottom: var(--g-spacing-xl);
    animation: pop-in var(--g-duration-xshort) ease-in-out;
    @keyframes pop-in {
        from {
            opacity: 0;
            visibility: hidden;
        }
        to {
            opacity: 1;
            transition: opacity var(--g-duration-xshort) ease-in-out;
            visibility: visible;
        }
    }
}
