@use "@wojo/ui/mixins" as *;

.checklist-overview-wrapper {
    margin-bottom: var(--g-spacing-xxl);
    padding: var(--g-spacing-md);

    @include breakpoint-sm {
        padding: var(--g-spacing-lg) var(--g-spacing-xl);
    }
}
.checklist-overview-list {
    padding: 0;
    margin: 0;
    list-style: none;

    > :first-child {
        padding-top: 0;
    }

    > :last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
}

.checklist-overview-item {
    padding: var(--g-spacing-md) 0;
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);

    &:hover {
        svg {
            color: var(--s-color-brand-primary-standard);
        }
    }
}

.checklist-overview-link {
    text-decoration: none;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    grid-template-areas:
        "name icon"
        "tasklabel icon";
    align-items: center;

    @include breakpoint-sm {
        grid-template-columns: 1fr auto auto;
        grid-template-areas: "name tasklabel icon";
    }

    &-name {
        grid-area: name;
    }

    &-adornment {
        grid-area: tasklabel;

        span {
            font-weight: var(--g-font-weight-regular);
        }
    }

    svg {
        grid-area: icon;

        @include breakpoint-sm {
            margin-left: var(--g-spacing-xl);
        }
    }

    &:hover {
        text-decoration: underline;
        color: var(--s-color-brand-primary-standard);
    }

    &:focus-visible {
        text-decoration: underline;
        border-radius: var(--s-border-radius-interactive-focus);
        outline-offset: var(--g-spacing-sm);
        outline-style: solid;
        outline-width: var(--g-spacing-xxs);
        outline-color: var(--s-color-brand-primary-standard);
        color: var(--s-color-brand-primary-standard);
    }
}
