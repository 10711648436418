@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .wrapper {
        display: flex;
        aspect-ratio: auto;
        width: 100%;
        height: 100%;
    }

    .video-player {
        aspect-ratio: auto;
        height: 100%;
        width: 100%;

        .loading-skeleton {
            svg {
                border-radius: 0;
            }
        }

        & > div {
            // Always set wistia embedded container to full width and height of video player
            height: 100% !important;
            width: 100% !important;
            display: flex;
        }
    }

    .wrapper--fullscreen {
        .video-player {
            video {
                object-fit: contain !important;
            }
        }
    }
}
