.link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 0;
    height: 0;

    font-family: var(--g-font-family-primary);
    font-size: var(--g-font-size-300);
    font-weight: var(--g-font-weight-regular);
    line-height: 16px;
    text-decoration: none;

    color: var(--s-color-brand-primary-standard);
    background-color: var(--s-color-surface-secondary);

    transition: none;
    opacity: 0;

    &:visited {
        color: inherit;
    }

    &:focus,
    &:focus-visible {
        display: block;
        width: 100%;
        height: auto;
        padding: var(--g-spacing-xl) 0;
        border: 2px solid var(--s-color-brand-primary-standard);
        color: var(--s-color-brand-primary-standard);
        text-align: center;
        z-index: 1;
        opacity: 1;
    }
}
