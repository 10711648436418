@use "../../styles/mixins" as *;
@include layer(ui) {
    .wrapper {
        box-sizing: border-box;
        margin-inline: auto;
        max-width: var(--c-container-xl);
        width: 100%;

        &--full {
            max-width: var(--c-container-full);
        }

        &--padded {
            @include breakpoint-xs {
                padding-inline: var(--container-spacing-xs);
            }

            @include breakpoint-sm {
                padding-inline: var(--container-spacing-sm);
            }

            @include breakpoint-md {
                padding-inline: var(--container-spacing-md);
            }

            @include breakpoint-lg {
                padding-inline: var(--container-spacing-lg);
            }

            @include breakpoint-xl {
                padding-inline: var(--container-spacing-xl);
            }
        }
    }

    .contents {
        &--full {
            max-width: var(--c-container-full);
        }

        &--xlarge {
            max-width: var(--c-container-xl);
        }

        &--large {
            max-width: var(--c-container-lg);
        }

        &--medium {
            max-width: var(--c-container-md);
        }

        &--small {
            max-width: var(--c-container-sm);
        }

        &--xsmall {
            max-width: var(--c-container-xs);
        }

        &--center {
            margin-inline: auto;
        }
        &--left {
            margin-right: auto;
        }
        &--right {
            margin-left: auto;
        }

        &-position {
            &--center {
                margin-inline: auto;
            }
            &--left {
                margin-right: auto;
            }
            &--right {
                margin-left: auto;
            }
        }
    }
}
