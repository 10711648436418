@use "@wojo/ui/mixins" as *;

.special-departures {
    border-radius: var(--s-border-radius-background-standard);
    outline: 1px solid transparent;
    padding: var(--g-spacing-lg);
    display: grid;
    gap: var(--g-spacing-sm);
    @include breakpoint-md {
        padding: var(--g-spacing-xl);
    }
}

.special-departures-content {
    display: flex;
    align-items: flex-start;
    gap: var(--g-spacing-sm);
}

.special-departures-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--g-spacing-sm) var(--g-spacing-lg);
    @include breakpoint-md {
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
    }
}
