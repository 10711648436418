@use "@wojo/ui/mixins" as *;

.header {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    width: 100vw;
    box-sizing: border-box;
    background: var(--g-color-white);
    top: 0;
    margin-left: calc(var(--container-spacing-sm) * -1);
    z-index: 1;
    margin-bottom: var(--container-spacing-xl);
    border-top-width: 0;

    @include breakpoint-md {
        margin-left: calc(var(--container-spacing-lg) * -1);
    }

    @include breakpoint-lg {
        display: none;
    }
}
