.success-text {
    color: var(--s-color-success-standard);
    font-weight: var(--g-font-weight-regular);
    line-height: var(--g-spacing-lg);
    display: grid;
    grid-template-columns: min-content auto;
    justify-content: flex-start;
    gap: var(--g-spacing-md);
}
.success-description {
    font-weight: var(--g-font-weight-light);
    margin: var(--g-spacing-md) 0;
    line-height: var(--g-spacing-lg);
}
