@use "../../../styles/mixins" as *;
@include layer(ui) {
    .requirements-wrapper {
        margin-top: 8px;
        font-size: var(--g-font-size-300);
        font-weight: var(--g-font-weight-light);
        display: grid;
        align-items: center;
        grid-template-columns: min-content 1fr;
        color: var(--s-color-alert-standard);
        column-gap: 8px;
    }
    .is-fulfilled {
        color: var(--s-color-success-standard);
    }
}
