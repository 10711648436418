@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .content-item {
        padding-bottom: var(--g-spacing-sm);
    }

    .content-container {
        display: flex;
        flex-direction: column;

        &--left {
            align-items: flex-start;
        }

        &--center {
            align-items: center;
            text-align: center;
        }
    }
}
