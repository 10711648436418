@use "@wojo/ui/mixins" as *;

.layout-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.background {
    display: flex;
    align-items: flex-start;
    width: 100%;
    background-color: var(--s-color-surface-primary);
    flex-grow: 1;
}

.my-account-container {
    padding: var(--g-spacing-md) 0;

    @include breakpoint-lg {
        --col-gap: var(--g-spacing-xl);
        --account-nav: 33%;
        --account-content: 67%;

        display: grid;
        padding: var(--g-spacing-xl) 0;
        gap: var(--col-gap);

        grid-template-columns:
            calc(var(--account-nav) - (var(--col-gap) * 0.5))
            calc(var(--account-content) - (var(--col-gap) * 0.5));
    }

    @include breakpoint-xl {
        --account-nav: 25%;
        --account-content: 75%;
    }
}
