@use "@wojo/ui/mixins" as *;

.desktop {
    display: none;

    @include breakpoint-lg {
        display: flex;
        gap: var(--g-spacing-xl);
    }
}

.container {
    flex-direction: column;
    max-width: 600px;
    @include breakpoint-lg {
        max-width: 368px;
    }
}

.mobile {
    display: flex;
    position: sticky;
    top: 0;
    margin-bottom: var(--g-spacing-md);
    padding: var(--g-spacing-md);

    z-index: 10;
    align-items: center;
    justify-content: space-between;
    background-color: var(--s-color-surface-secondary);
    box-shadow: var(--s-shadow-down);

    @include breakpoint-lg {
        display: none;
    }
}

.mobile-modal {
    display: grid;
    gap: var(--g-spacing-xl);
    justify-items: center;
}
