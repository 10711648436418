.title {
    margin-bottom: var(--g-spacing-sm);
}

.description {
    margin-bottom: var(--g-spacing-sm);
}

.wrapper {
    margin-bottom: var(--g-spacing-xl);
}

.image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}
