@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .wrapper {
        display: flex;
        padding: 0;
        border-width: 0;
        overflow: hidden;
        border-radius: var(--s-border-radius-background-standard);
        flex-direction: column;
        width: 100%;
        height: max-content;

        &:hover {
            svg {
                transform: translateX(var(--g-spacing-sm));
            }
        }

        &:hover {
            img {
                transform: scale(1.05);
            }
        }

        & > *:focus-visible,
        & > *:focus {
            outline: none;
        }

        &:focus-visible,
        &:focus-within {
            outline: var(--g-border-width-sm) solid
                var(--s-color-brand-primary-standard);
            outline-offset: unset;
            transition: outline none;
        }

        &-background-color {
            background-color: var(--page-link-background-color);
        }

        &-background-gradient {
            background: linear-gradient(
                var(--page-link-gradient-angle),
                var(--page-link-background-start-color),
                var(--page-link-background-end-color)
            );
        }

        &:link,
        &:visited,
        &:hover,
        &:active {
            text-decoration: none;
        }

        @media (forced-colors: active) {
            border: var(--g-border-width-sm) solid transparent;
        }
    }

    .card {
        transition: filter var(--g-duration-xshort) ease;
    }
}
