@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .card {
        --aspect-ratio: auto;
        --gradient-angle: 0deg;
        --overlay-color: 0, 0, 0;
        --overlay-opacity: 0.4;

        border-width: 0;
        aspect-ratio: var(--aspect-ratio);
        padding: 0;
        display: flex;
        &--no-border-radius {
            border-radius: 0;
        }
        &--no-background {
            background-color: transparent;
        }

        @media (forced-colors: active) {
            border-width: 2px;
        }
    }

    .border-container {
        border-radius: inherit;
        aspect-ratio: inherit;
        box-sizing: border-box;
        min-height: 100%;
        width: 100%;
    }

    .content-container {
        padding: var(--g-spacing-lg);
        display: flex;
        flex-direction: column;
        min-height: 100%;
        min-width: 100%;
        box-sizing: border-box;
        border-radius: inherit;
        overflow: hidden;
        &,
        & > * {
            position: relative;
        }
        @include for-tablet-up {
            padding: var(--g-spacing-xl);
        }
        &--x-left {
            align-items: flex-start;
            text-align: left;
        }
        &--x-center {
            align-items: center;
            text-align: center;
        }
        &--y-top {
            justify-content: flex-start;
        }
        &--y-middle {
            justify-content: center;
        }
        &--y-bottom {
            justify-content: flex-end;
        }
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;

        img {
            .card--button:hover &,
            .card--button:focus-visible & {
                transition: transform var(--g-duration-xshort) ease;
                transform: scale(1.025);
            }

            @media (prefers-reduced-motion: reduce) {
                transition: none;
            }
        }
    }

    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
            var(--gradient-angle),
            transparent,
            rgba(var(--overlay-color), var(--overlay-opacity))
        );
        &--top {
            top: 0;
        }
        &--middle {
            background-image: none;
            background-color: rgba(
                var(--overlay-color),
                var(--overlay-opacity)
            );
        }
        @include for-tablet-up {
            // overlay takes over full card when text is in middle
            &:not(.overlay--middle) {
                height: 66%;
            }
        }
    }

    .text {
        &--light-text {
            color: var(--g-color-white);
        }
        &--dark-text {
            color: var(--g-color-ink-black);
        }
        &--2 {
            margin-bottom: var(--g-spacing-sm);
        }
        &--3 {
            margin-bottom: var(--g-spacing-xs);
        }
        &--4,
        &--5,
        &--6 {
            margin-bottom: var(--g-spacing-xxs);
        }
    }

    .cta {
        margin-top: var(--g-spacing-md);
    }
}
